
























































































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';
import RequestDetailViewModel from
  '@/vue-app/view-models/allianz-dashboard/allianz-actions/pending-requests/request-detail-view-model';

// Domain
import {
  PendingTransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/pending-transaction-entity';

@Component({
  name: 'RequestDetailDialog',
  filters: { currencyFormat },
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RequestDetailDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('request', { type: Object, required: true })
  synced_request!: PendingTransactionEntity;

  request_detail_view_model = Vue.observable(new RequestDetailViewModel(this));

  @Watch('request')
  onInvestmentProvidersChange(request: PendingTransactionEntity) {
    this.request_detail_view_model.initialize(request);
  }

  goBack() {
    this.synced_is_open = false;
  }

  mounted() {
    this.request_detail_view_model.initialize(this.synced_request);
  }
}
