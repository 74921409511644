import TYPES from '@/types';

import Vue from 'vue';

// Domain
import {
  PendingTransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/pending-transaction-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class RequestDetailViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.allianz-dashboard.allianz_requests';

  readonly view!: Vue;

  readonly disabled_options = ['RecurringContribution', 'PaymentRetry', 'Transfer'];

  disabled_button_cancel = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  close = () => {
    this.view.$emit('close');
  }

  cancelRequest = () => {
    this.view.$emit('cancelRequest');
  }

  validateRequestType = (request: PendingTransactionEntity) => {
    if (this.disabled_options.indexOf(request.transaction_type) >= 0) {
      this.disabled_button_cancel = true;
    }
  }

  validateRequestStatus = (request: PendingTransactionEntity) => {
    if (request.transaction_status.toLowerCase() !== 'pending') {
      this.disabled_button_cancel = true;
    } else {
      this.validateRequestType(request);
    }
  }

  initialize = (request: PendingTransactionEntity) => {
    this.disabled_button_cancel = false;
    this.validateRequestStatus(request);
  }
}
